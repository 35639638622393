import Vue from 'vue'
import Vuex from 'vuex'
import main from './modules/main'
import file from './modules/file'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    file,
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
})
