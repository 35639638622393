import axios from "axios";
import VueCookies from 'vue-cookies'

const state = {
    token: '',
    errors: {},
    authenticated: false,
    userData: {},
    clientData: {},
    driversLicenseData: [],
    secureRentData: [],
    leasingReturnData: [],
    costEvaluationData: [],
    vehicleEstimateData: [],
    reMarketingData: [],
    imageSources: [],
    secureRentImageSources: [],
    remarketingImageSources: [],
};

const getters = {
    personalToken: (state) => state.token,
    isAuthenticated: (state) => state.authenticated,
    userData: (state) => state.userData,
    clientData: (state) => state.clientData,
    getDriversLicenseData: (state) => state.driversLicenseData,
    getSecureRentData: (state)=> state.secureRentData,
    getLeasingReturnData: (state) => state.leasingReturnData,
    getCostEvaluationData: (state) => state.costEvaluationData,
    getVehicleEstimateData: (state) => state.vehicleEstimateData,
    getRemarketingData: (state) => state.reMarketingData,
    imageSources: (state) => state.imageSources,
    secureRentImageSources: (state) => state.secureRentImageSources,
    remarketingImageSources: (state) => state.remarketingImageSources,
};

const actions = {
    saveToken({ commit }, token) {
        $cookies.set('token', token, '30d');
        commit('SAVE_TOKEN', token);
        commit('CHANGE_AUTH', true);
    },
    saveUserData({ commit }, userData) {
        commit('SAVE_USERDATA', userData);
    },
    saveClientData({ commit }, clientData) {
        clientData.remarketing_template = "https://api.autolytic.de/"+clientData.remarketing_template;
        commit('SAVE_CLIENTDATA', clientData);
    },
    deleteToken({ commit }) {
        $cookies.remove('token');
        commit('REMOVE_TOKEN');
        commit('CHANGE_AUTH', false);
    },
    getToken({ commit }) {
        var token = $cookies.get('token');
        commit('SAVE_TOKEN', token);
        if (token) {
            commit('CHANGE_AUTH', true);
        }
    },
    saveAuth({ commit }, credentials) {
        const token = $cookies.get('token');
        commit('SAVE_TOKEN', token);
        commit('CHANGE_AUTH', credentials.isAuthenticated);
    },
    saveImageSources({ commit }, sources){
        commit('IMAGE_SOURCES', sources);
    },
    saveSecureRentImageSources({ commit }, sources){
        commit('SECURERENT_IMAGE_SOURCES', sources);
    },
    saveRemarketingImageSources({ commit }, sources){
        commit('REMARKETINGIMAGE_SOURCES', sources);
    },
    async checkAuthentication({ commit, dispatch }) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('token-check', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            const authentication = {
                isAuthenticated: response.data['authenticated'],
            };

            if (authentication.isAuthenticated) {
                dispatch('saveAuth', authentication);
            }
            else {
                commit('REMOVE_TOKEN', false);
                commit('CHANGE_AUTH', false);
            }

        } catch (error) {
            commit('CHANGE_AUTH', false);
        }
    },
    async login({ commit, dispatch }, credentials) {
        try {

            const response = await axios.post('https://api.autolytic.de/api/login', credentials);
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {
                
                dispatch('saveToken', response.data['token']);
                dispatch('saveUserData', response.data['user'] );
                dispatch('saveClientData', response.data['client'] );
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    saveDriversLicenseData({ commit }, driversLicenseData) {
        commit('SAVE_DRIVERSLICENSEDATA', driversLicenseData);
    },
    saveSecureRentData({ commit }, secureRentData) {
        commit('SAVE_SECURERENTDATA', secureRentData);
    },
    saveLeasingReturnData({ commit }, leasingReturnData) {
        commit('SAVE_LEASINGRETURNDATA', leasingReturnData);
    },
    saveCostEvaluationData({ commit }, costEvaluationData) {
        commit('SAVE_COSTEVALUATIONDATA', costEvaluationData);
    },
    saveVehicleEstimateData({ commit }, vehicleEstimateData) {
        commit('SAVE_VEHICLEESTIMATEDATA', vehicleEstimateData);
    },
    saveRemarketingData({ commit }, reMarketingData) {
        commit('SAVE_REMARKETINGDATA', reMarketingData);
    },

    async loadDriversLicenseData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getdriverslicensesessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveDriversLicenseData', response.data['data']);

                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async loadSecureRentData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getsecurerentsessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveSecureRentData', response.data['data']);

                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async loadCostEvaluationData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getcostestimatesessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveCostEvaluationData', response.data['data']);
    
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async loadLeasingReturnData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getleasingreturnsessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveLeasingReturnData', response.data['data']);
    
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async loadVehicleEstimateData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getvehicleevaluationsessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveVehicleEstimateData', response.data['data']);
    
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async loadRemarketingData({ commit, dispatch },) {
        try {
            const token = $cookies.get('token');
            const response = await axios.get('https://api.autolytic.de/api/getremarketingsessions', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveRemarketingData', response.data['data']);
    
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async updateDriversLicenseSession({ commit, dispatch }, data) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('https://api.autolytic.de/api/updatedriverslicensesession', data, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('loadDriversLicenseData');

                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async updateDataset({ commit, dispatch }, data, sessiontype) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('https://api.autolytic.de/api/updatecommonsession', data, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {   
                switch(sessiontype) {
                    case 1:
                        dispatch('loadCostEvaluationData');
                      break;
                    case 2:
                        dispatch('loadDriversLicenseData');
                      break;
                      case 3:
                        dispatch('loadVehicleEstimateData');
                        break;
                    default:
                      // code block
                  }   
                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async retrieveImageSources({ commit, dispatch }, dataset_id) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('https://api.autolytic.de/api/retrievefiles', { id: dataset_id }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveImageSources', response.data['data']);

                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async retrieveSecureRentImageSources({ commit, dispatch }, dataset_id) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('https://api.autolytic.de/api/retrievesecurerentfiles', { id: dataset_id }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {
                // Combine 'picked' and 'returned' arrays
                const allImages = [...response.data['data']['picked'], ...response.data['data']['returned']];
                dispatch('saveSecureRentImageSources', allImages);
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    },
    async retrieveRemarketingImageSources({ commit, dispatch }, dataset_id) {
        try {
            const token = $cookies.get('token');
            const response = await axios.post('https://api.autolytic.de/api/retrieveremarketingfiles', { id: dataset_id }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.data['error']) {
                commit('ERRORS', 'invalid credentials');
            }
            else {     
                dispatch('saveRemarketingImageSources', response.data['data']);

                
            }
        } catch (error) {
            console.log(`no commit errors = ${error}`);
        }
    }

};

const mutations = {
    ERRORS: (state, error) => {
        console.log(`errors = ${error}`);
        (state.errors = error);
    },
    SAVE_TOKEN: (state, token) => {
        (state.token = token)
    },
    SAVE_USERDATA: (state, userData) => {
        (state.userData = userData)
    },
    SAVE_CLIENTDATA: (state, clientData) => {
        (state.clientData = clientData)
    },
    REMOVE_TOKEN: (state) => {
        (state.token = '')
    },
    CHANGE_AUTH: (state, authentication) => {
        (state.authenticated = authentication)
    },
    SAVE_DRIVERSLICENSEDATA: (state, driversLicenseData) => {
        (state.driversLicenseData = driversLicenseData)
    },
    SAVE_SECURERENTDATA: (state, secureRentData) => {
        (state.secureRentData = secureRentData)
    },
    SAVE_LEASINGRETURNDATA: (state, leasingReturnData) => {
        (state.leasingReturnData = leasingReturnData)
    },
    SAVE_COSTEVALUATIONDATA: (state, costEvaluationData) => {
        (state.costEvaluationData = costEvaluationData)
    },
    SAVE_COSTEVALUATIONDATA: (state, costEvaluationData) => {
        (state.costEvaluationData = costEvaluationData)
    },
    SAVE_VEHICLEESTIMATEDATA: (state, vehicleEstimateData) => {
        (state.vehicleEstimateData = vehicleEstimateData)
    },
    SAVE_REMARKETINGDATA: (state, reMarketingData) => {
        (state.reMarketingData = reMarketingData)
    },
    IMAGE_SOURCES: (state, sources) => {
        (state.imageSources = sources);
    },
    SECURERENT_IMAGE_SOURCES: (state, sources) => {  (state.secureRentImageSources = sources);
    },
    REMARKETINGIMAGE_SOURCES: (state, remarketingImageSources) => {
        (state.remarketingImageSources = remarketingImageSources);
    },
    CLEAR_SECURERENT_IMAGE_SOURCES(state) {
        state.secureRentImageSources = [];
      },
      
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
