import axios from "axios";
// import download from 'downloadjs';

const state = {
    input: {
        excel: null,
    },
};

const getters = {
    input: (state) => state.input,
};

const actions = {
    async upload({ commit, rootGetters }, file_input) {
        try {
            const token = $cookies.get('token');
            var formData = new FormData();
            formData.append("excel", file_input.excel);
            await axios.post('https://api.autolytic.de/api/addbulkexcel', formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    //"Content-Type": "multipart/form-data"
                }
            });
            commit('EXCEL_FILE', file_input.excel);
        } catch (error) {
            console.log(`error ${error}`);
        }
    },
};

const mutations = {
    EXCEL_FILE: (state, file) => {
        (state.input.excel = file)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}