import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
    meta: {
      auth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      auth: true,
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/driverslicense',
    name: 'driverslicense',
    meta: {
      auth: true,
    },

    component: () => import('@/views/pages/sessions/DriversLicense.vue'),
  },
  {
    path: '/leasingreturn',
    name: 'leasingreturn',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/sessions/Leasingreturn.vue'),
  },
  {
    path: '/vehicleestimate',
    name: 'vehicleestimate',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/sessions/Vehicleestimate.vue'),
  },
  {
    path: '/costevaluation',
    name: 'costevaluation',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/sessions/CostEvaluation.vue'),
  },
  {
    path: '/remarketing',
    name: 'remarketing',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/sessions/Remarketing.vue'),
  },
  {
    path: '/securerent',
    name: 'securerent',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/sessions/SecureRent.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/pages/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // Use the page's router title to name the page
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  store.dispatch('main/getToken')
  const token = store.getters['main/personalToken']

  if (to.meta && to.meta.auth !== undefined) {
    // if the page requires auth
    if (to.meta.auth) {
      //check if token exists
      if (!token) {
        router.push({ name: 'login' })
        return
      }
      next()
      return
    }
  }
  if (token) {
    router.push({ name: 'dashboard' })
    return
  }

  next()
  return
})

export default router
